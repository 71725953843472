var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.widget.compType === "divider",
          expression: "widget.compType === 'divider'",
        },
      ],
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("lang_component_name") } },
        [
          _c("lang-input", {
            staticClass: "input",
            attrs: { placeholder: _vm.$t("lang_please_enter"), clearable: "" },
            model: {
              value: _vm.widget.compName,
              callback: function ($$v) {
                _vm.$set(_vm.widget, "compName", $$v)
              },
              expression: "widget.compName",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("lang_component_code") } },
        [
          _c("el-input", {
            staticClass: "input",
            attrs: { placeholder: _vm.$t("lang_please_enter"), clearable: "" },
            model: {
              value: _vm.widget.compCode,
              callback: function ($$v) {
                _vm.$set(_vm.widget, "compCode", $$v)
              },
              expression: "widget.compCode",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("lang_title_Color") } },
        [
          _c("el-color-picker", {
            model: {
              value: _vm.widget.properties.fontColor,
              callback: function ($$v) {
                _vm.$set(_vm.widget.properties, "fontColor", $$v)
              },
              expression: "widget.properties.fontColor",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("lang_title_size") } },
        [
          _c("el-input-number", {
            staticClass: "input",
            attrs: { placeholder: _vm.$t("lang_please_enter") },
            model: {
              value: _vm.widget.properties.fontSize,
              callback: function ($$v) {
                _vm.$set(_vm.widget.properties, "fontSize", $$v)
              },
              expression: "widget.properties.fontSize",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("lang_label_position") } },
        [
          _c(
            "el-select",
            {
              model: {
                value: _vm.widget.properties.labelPosition,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "labelPosition", $$v)
                },
                expression: "widget.properties.labelPosition",
              },
            },
            [
              _c("el-option", {
                attrs: { label: _vm.$t("lang_align_left"), value: "left" },
              }),
              _c("el-option", {
                attrs: { label: _vm.$t("lang_center"), value: "center" },
              }),
              _c("el-option", {
                attrs: { label: _vm.$t("lang_align_right"), value: "right" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("lang_label_border") } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.widget.properties.cellBorder,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "cellBorder", $$v)
                },
                expression: "widget.properties.cellBorder",
              },
            },
            [
              _c("el-radio", { attrs: { label: true } }, [
                _vm._v(_vm._s(_vm.$t("lang_display"))),
              ]),
              _c("el-radio", { attrs: { label: false } }, [
                _vm._v(_vm._s(_vm.$t("lang_hide"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("lang_title_position") } },
        [
          _c(
            "el-select",
            {
              model: {
                value: _vm.widget.properties.contentPosition,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "contentPosition", $$v)
                },
                expression: "widget.properties.contentPosition",
              },
            },
            [
              _c("el-option", {
                attrs: { label: _vm.$t("lang_align_left"), value: "left" },
              }),
              _c("el-option", {
                attrs: { label: _vm.$t("lang_center"), value: "center" },
              }),
              _c("el-option", {
                attrs: { label: _vm.$t("lang_align_right"), value: "right" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("lang_line_color") } },
        [
          _c("el-color-picker", {
            model: {
              value: _vm.widget.properties.lineColor,
              callback: function ($$v) {
                _vm.$set(_vm.widget.properties, "lineColor", $$v)
              },
              expression: "widget.properties.lineColor",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("lang_line_thickness") } },
        [
          _c("el-input-number", {
            staticClass: "input",
            attrs: { placeholder: _vm.$t("lang_please_enter") },
            model: {
              value: _vm.widget.properties.lineWidth,
              callback: function ($$v) {
                _vm.$set(_vm.widget.properties, "lineWidth", $$v)
              },
              expression: "widget.properties.lineWidth",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("lang_operation_status") } },
        [
          _c(
            "el-select",
            {
              attrs: {
                placeholder: _vm.$t("lang_please_select"),
                clearable: "",
              },
              model: {
                value: _vm.widget.properties.operation,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "operation", $$v)
                },
                expression: "widget.properties.operation",
              },
            },
            _vm._l(_vm.statusList, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.label, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }